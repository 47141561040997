<div class="col-md-6 offset-md-3">
  <div class="" *ngIf="editForm">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">
      <input formControlName="giftId" type="hidden" />

      <app-fund-search *ngIf="fundNumber"
                       [searchFor]="fundNumber"
                       (searchResultSelectionChanged)="onFundSelected($event)">
      </app-fund-search>

      <div>
        <hr />
      </div>

      <app-gift-amount *ngIf="amount" (amountChanged)="onAmountChanged($event)"
                       [amount]="amount"></app-gift-amount>

      <div class="alert alert-danger mt-md-1" *ngIf="amountControl.invalid && (amountControl.dirty || amountControl.touched || submitting)">
        <div *ngIf="amountControl.errors.required">A donation amount must be selected</div>
        <div *ngIf="amountControl.errors.min">Donation must be between $1 and $99,999</div>
        <div *ngIf="amountControl.errors.max">Donation must be between $1 and $99,999</div>
      </div>

      <div *ngIf="attempts >= 3">
        <app-validation-failure [form]="editForm"></app-validation-failure>
      </div>

      <div class="d-flex">
        <button id="cancel" type="button" class="btn btn-navigation w-100 mt-3 mb-3" (click)="onCancel()">
          <i class="fa fa-chevron-left"></i> Cancel And Review
        </button>
        <button id="saveGift" type="submit" class="btn btn-navigation w-100 mt-3 mb-3">
          Update Gift Basket
        </button>
      </div>
    </form>
  </div>



</div>
