<div class="col-md-6 offset-md-3 mt-5">
  <div class="card">
    <div class='card-header bg-blue text-white'>
      {{pageTitle}}
    </div>

    <div class="card-body">

      <div class="" *ngIf="fund">
        <div class="row">
          <div class="col-12 emphasized-text designation-name">
            {{ fund.publicName }}
          </div>
        </div>
        <div class="row pb-2">
          <div class="col-md-12">
            {{ fund.purposeDescription }}
          </div>
        </div>
        <div class="row mb-2 pb-2">
          <div class="col-md-12 less-emphasized-text">
            {{ "Fund Number: " + fund.fundNumber }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="addForm">
    <div class="mt-4">
      <app-gift-amount (amountChanged)="onAmountChanged($event)" [amount]="-1"></app-gift-amount>
    </div>

    <div class="alert alert-danger" *ngIf="amountControl.invalid && (amountControl.dirty || amountControl.touched || submitting)">
      <div *ngIf="amountControl.errors.required">A donation amount must be selected</div>
      <div *ngIf="amountControl.errors.min">Donation must be between $1 and $99,999</div>
      <div *ngIf="amountControl.errors.max">Donation must be between $1 and $99,999</div>
    </div>

    <div *ngIf="attempts >= 3">
      <app-validation-failure [form]="addForm"></app-validation-failure>
    </div>
  </div>

    <div class="d-flex mt-4 mb-3">
    <button id="saveGift" type="submit" class="btn btn-navigation w-100" (click)="onSubmit()">
      <i class="fa fa-floppy-o"></i> Save Gift
    </button>
  </div>


</div>


