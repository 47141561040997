<div class="" data-toggle="buttons" (click)="onAmountOptionsClick($event)">
  <div class="btn-group d-flex">
    <label id="lblOption50"
           [ngClass]="{
           'btn btn-amount w-100' : true,
           'active' : amount === 50}">
      <input type="radio" name="amountOptions" id="option50"/>$50
    </label>
    <label id="lblOption100"
           [ngClass]="{
           'btn btn-amount w-100' : true,
           'active' : amount === 100}">
      <input type="radio" name="amountOptions" id="option100" />$100
    </label>
    <label id="lblOption250"
           [ngClass]="{
           'btn btn-amount w-100' : true,
           'active' : amount === 250}">
      <input type="radio" name="amountOptions" id="option250" />$250
    </label>
  </div>
  <div class="btn-group d-flex">
    <label id="lblOption500"
           [ngClass]="{
           'btn btn-amount w-100' : true,
           'active' : amount === 500}">
      <input type="radio" name="amountOptions" id="option500" />$500
    </label>
    <label id="lblOption1000"
           [ngClass]="{
           'btn btn-amount w-100' : true,
           'active' : amount === 1000}">
      <input type="radio" name="amountOptions" id="option1000" />$1,000
    </label>
    <label id="lblOption2500"
           [ngClass]="{
           'btn btn-amount w-100' : true,
           'active' : amount === 2500}">
      <input type="radio" name="amountOptions" id="option2500" />$2,500
    </label>
  </div>
  <div class="btn-group d-flex">
    <label id="lblOptionOther"
           [ngClass]="{
           'btn btn-amount w-50' : true,
           'active' : useOtherAmount}">
      <input type="radio" name="amountOptions" id="optionOther" />Other Amount
    </label>
    <div class="w-50" *ngIf="useOtherAmount">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fa fa-usd"></i></span>
        </div>
        <input class="form-control" type="number" id="otherAmountTxt" placeholder="Other" [(ngModel)]="amount" min="0" onkeydown="if(event.key==='.' || event.keyCode===110){event.preventDefault();}" (ngModelChange)="onOtherAmountChanged($event)"/>
      </div>
    </div>
  </div>
</div>

