import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UnifiedCheckoutComponent} from "@features/unified-checkout/unified-checkout.component";
import {SharedModule} from "@shared/shared.module";
import {CoreModule} from "@core/core.module";
import {AddressComponent} from "@features/address/address.component";
import {PayrollDeductionComponent} from "@features/payroll-deduction/payroll-deduction.component";
import {MatchingGiftComponent} from "@features/matching-gift/matching-gift.component";
import {MatchingGiftSearchComponent} from "@features/matching-gift/matching-gift-search/matching-gift-search.component";
import {FooterComponent} from "@features/footer/footer.component";
import {YourInfoComponent} from "@features/your-info/your-info.component";
import {ConfirmationComponent} from "@features/confirmation/confirmation.component";
import {FundDescriptionComponent} from "@features/fund-description/fund-description.component";
import {FundSearchComponent} from "@features/fund-search/fund-search.component";
import {NgxMaskDirective, provideNgxMask} from "ngx-mask";
import {GiftAddReferralComponent} from "@features/gift-add-referral/gift-add-referral.component";
import {GiftEditComponent} from "@features/gift-edit/gift-edit.component";
import {GiftAmountComponent} from "@features/gift-amount/gift-amount.component";
import {DirectLinkComponent} from "@features/direct-link/direct-link.component";
import {AppealCodeComponent} from "@features/appeal-code/appeal-code.component";
import {LinkGeneratorComponent} from "@features/link-generator/link-generator.component";
import {GiftAddComponent} from "@features/gift-add/gift-add.component";
import {AcknowledgeeComponent} from "@features/acknowledgee/acknowledgee.component";
import {GiftListComponent} from "@features/gift-list/gift-list.component";
import {RecurrenceComponent} from "@features/recurrence/recurrence.component";
import {SelectGiftTypeComponent} from "@features/select-gift-type/select-gift-type.component";
import {SelectPaymentTypeComponent} from "@features/select-payment-type/select-payment-type.component";
import {TributeComponent} from "@features/tribute/tribute.component";
import {GiftReviewComponent} from "@features/gift-review/gift-review.component";
import {NgxCaptchaModule} from "ngx-captcha";


@NgModule({
  declarations: [
    UnifiedCheckoutComponent,
    AddressComponent,
    PayrollDeductionComponent,
    MatchingGiftComponent,
    MatchingGiftSearchComponent,
    FooterComponent,
    YourInfoComponent,
    ConfirmationComponent,
    FundDescriptionComponent,
    FundSearchComponent,
    GiftAddReferralComponent,
    GiftAmountComponent,
    GiftEditComponent,
    DirectLinkComponent,
    AppealCodeComponent,
    LinkGeneratorComponent,
    GiftAddComponent,
    AcknowledgeeComponent,
    GiftReviewComponent,
    GiftListComponent,
    RecurrenceComponent,
    SelectGiftTypeComponent,
    SelectPaymentTypeComponent,
    TributeComponent
  ],
  imports: [
    CommonModule,
    SharedModule, // HACK: Move imports from shared to features once features are migrated
    CoreModule,
    NgxMaskDirective,
    NgxCaptchaModule

  ],
  exports: [
    FooterComponent
  ],
  providers: [
    provideNgxMask()
  ]
})
export class FeaturesModule { }
